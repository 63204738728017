/*===========================
    Search Oprtion 
============================*/

.search-flyoverlay-area{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(8,8,8,.9);
    text-align: center;
    transition: all ease-in-out .25s;
    z-index: 12;
    color: #fff;
    &::before{
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -.25em;
    }

    .searchform-fly{
        display: inline-block;
        max-width: 90%;
        vertical-align: middle;
        text-align: center;
        font-size: 18px;
        transform: scale(.9);
        -webkit-transform: scale(.9);
        opacity: 0;
        visibility: hidden;
        transition: all ease-in-out .3s;
        p{
            font-weight: 300;
            font-size: 18px;
            margin: 0 0 15px;
            text-align: center;
        }
        .search-form{
            width: 800px;
            max-width: 100%;
            margin: 0 auto;
            line-height: 1.714286;
            font-weight: 400;
            position: relative;
            input{
                width: 100%;
                background-color: transparent;
                border: 0;
                border-bottom: 2px solid #fff;
                text-align: center;
                font-size: 30px;
                padding: 19px 10px;
                transition: all .3s ease-out;
                font-weight: 300;
                max-width: 100%;
                background: transparent;
                -webkit-appearance: none;
                border-radius: 0;
                margin-bottom: 0;
                color: #fff;
                height: auto;
            }
            button{
                font-size: 30px;
                height: 30px;
                color: inherit;
                display: block;
                right: 5px;
                border: none;
                color: #000;
                background: 0 0;
                padding: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                color: #fff;
                cursor: pointer;
                
            }
        }


    }
    .btn-close-search{
        font-size: 40px;
        display: block;
        position: absolute;
        z-index: 5;
        top: 20%;
        right: 20%;
        line-height: 40px;
        height: 40px;
        transition: all .3s;
        cursor: pointer;
        &:hover{
            transform: rotate(90deg);
            -webkit-transform: rotate(90deg);
            color: #f53e6a;
        }
    }

    &.is-visible{
        visibility: visible;
        opacity: 1;
        .searchform-fly{
            visibility: visible;
            opacity: 1;
            transform: scale(1);
            -webkit-transform: scale(1);
        }
    }
}


.search-flyoverlay-area .searchform-fly .search-form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
    font-weight: 300;
  }
  
.search-flyoverlay-area .searchform-fly .search-form input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fff;
    font-weight: 300;
}

.search-flyoverlay-area .searchform-fly .search-form input::-ms-input-placeholder { /* Microsoft Edge */
    color: #fff;
    font-weight: 300;
}













