/* ---------------------------
    Video Area 
-----------------------------*/



.comp-video{
    &.line--between,
    .line--between{
        position: relative;
        &::before ,
        &::after{
            content: "";
            position: absolute;
            top: 40px;
            height: 1px;
            width: calc(50% - 85px);
            background: #eee;
        }
        &::before{
            left: 0;
        }
        &::after{
            right: 0;
        }
    }
}
.line--between{
    position: relative;
    &::before ,
    &::after{
        content: "";
        position: absolute;
        top: 40px;
        height: 1px;
        width: calc(50% - 85px);
        background: #eee;
    }
    &::before{
        left: 0;
    }
    &::after{
        right: 0;
    }
}


// Video Button

.video-btn {
    text-align: center;
    &.breadcaump-video-position{
        position: absolute;
        left: 58%;
        bottom: 30px;
    }
    a {
        &.video-icon ,
        .video-icon {
            display: block;
            position: relative;
            margin: 0 auto;
            width: 72px;
            height: 72px;
            border-radius: 50%;
            background: $theme-color;
            -webkit-transition: all 1s cubic-bezier(0, 0, .2, 1);
            transition: all 1s cubic-bezier(0, 0, .2, 1);
            box-shadow: 0 20px 30px rgba(0, 0, 0, .2);
            
            &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                width: 0;
                height: 0;
                -webkit-transform: translate(-6px, -9px);
                -ms-transform: translate(-6px, -9px);
                transform: translate(-6px, -9px);
                border-top: 9px solid transparent;
                border-bottom: 9px solid transparent;
                border-left: 15px solid #fff;
            }

            
            &.second-icon{
                width: 90px;
                height: 90px;
                &::before{
                    border-top-width: 11px;
                    border-bottom-width: 11px;
                    border-left-width: 19px;
                    transform: translate(-6px, -12px);
                }
            }

            &.third-icon{
                width: 110px;
                height: 110px;
                &::before{
                    border-top-width: 14px;
                    border-bottom-width: 14px;
                    border-left-width: 24px;
                    -webkit-transform: translate(-50%,-50%);
                    -ms-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);
                }
            }

            &.four-icon{
                width: 130px;
                height: 130px;
                &::before{
                    border-top-width: 16px;
                    border-bottom-width: 16px;
                    border-left-width: 28px;
                    -webkit-transform: translate(-50%,-50%);
                    -ms-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);
                }
            }

          
            &.third-icon{
                &.brown-color{
                    background: #fff;
                    &::before{
                        border-left: 24px solid currentColor;
                    }
                }
            }



            &:hover {
                -webkit-transform: scale3d(1.15, 1.15, 1.15);
                transform: scale3d(1.15, 1.15, 1.15);
            }
        }

    }
    a{
        &.watch-text ,
        .watch-text{
            color: $body-color;
            margin: 32px 0 0;
            font-size: 14px;
            border: 0 none;
            padding: 0;
            display: block;
            font-weight: 600;
            -webkit-transition: all 1s cubic-bezier(0, 0, .2, 1);
            transition: all 1s cubic-bezier(0, 0, .2, 1);
            &:hover{
                color: $theme-color;
            }

        }
    }
}



.video-btn-2{
    .icon-gradient{
        width: 130px;
        height: 130px;
        box-shadow: none;
        background: none;
        display: block;
        margin: 0 auto;
        -webkit-transition: all 1s cubic-bezier(0,0,.2,1)!important;
        transition: all 1s cubic-bezier(0,0,.2,1)!important;
        border-radius: 50%;
        position: relative;
        z-index: 2;

        &::before{
            -webkit-transition: all .5s;
            transition: all .5s;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
            opacity: .15;
            position: absolute;
            content: "";
            border-radius: 50%;
            background-color: $theme-color;
            background-image: linear-gradient(-133deg,#7f152e 0,$theme-color 100%)
        }
        &::after{
            background-color: $theme-color;
            background-image: linear-gradient(-133deg,#7f152e 0,$theme-color 100%);
            width: 90px;
            height: 90px;
            top: 20px;
            left: 20px;
            z-index: -1;
            position: absolute;
            content: "";
            border-radius: 50%;
        }

        .video-icon{
            position: absolute;
            top: 50%;
            left: 50%;
            line-height: 1;
            margin-left: 1px;
            z-index: 3;
            -webkit-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            &::before{
                content: "";
                top: 0;
                left: 0;
                width: 0;
                height: 0;
                position: absolute;
                -webkit-transform: translate(-50%,-50%);
                -ms-transform: translate(-50%,-50%);
                transform: translate(-50%,-50%);
                border-top: 9px solid transparent;
                border-bottom: 9px solid transparent;
                border-left: 15px solid #fff;
                border-top-width: 11px;
                border-bottom-width: 11px;
                border-left-width: 19px;
            }
        }

        &:hover{
            &::before{
                -webkit-transform: scale3d(1.15,1.15,1.15);
                transform: scale3d(1.15,1.15,1.15);
                opacity: 0;
            }
            &.color-pink{
                &::before{
                    -webkit-transform: scale3d(1.15,1.15,1.15);
                    transform: scale3d(1.15,1.15,1.15);
                    opacity: 0;
                }
            }
        }
    }
    &.watch-text ,
    .watch-text{
        color: $body-color;
        margin: 32px 0 0;
        font-size: 14px;
        border: 0 none;
        padding: 0;
        display: block;
        font-weight: 600;
        -webkit-transition: all 1s cubic-bezier(0, 0, .2, 1);
        transition: all 1s cubic-bezier(0, 0, .2, 1);
        &:hover{
            color: $theme-color;
        }

    }
}

// Video With Images

.video-with-thumb{
    position: relative;
    .position--center{
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }
}



.video-custom-button{
    text-align: center;
    .play-button{
        padding: 30px;
        position: relative;
        display: inline-block;
        &::before{
            content: '';
            border: 1px solid #fff;
            position: absolute;
            left: -30px;
            right: -30px;
            bottom: -30px;
            top: -30px;
            border-radius: 50%;
            opacity: .3;
            transition: all .3s;
        }
        &::after{
            content: '';
            border: 1px solid #fff;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            border-radius: 50%;
            opacity: .5;
            transition: all .3s;
        }
        &:hover{
            &::before{
                transform: scale(1.1);
                -webkit-transform: scale(1.1);
            }
            &::after{
                transform: scale(1.05);
                -webkit-transform: scale(1.05);
            }
        }
    }
}


.tecnology-video{
    position: relative;
    &::before{
        background-color: transparent;
        background-image: radial-gradient(at center center,#3023ae 0%,#4a1b87 0%);
        opacity: 0.9;
        transition: background .3s,border-radius .3s,opacity .3s;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        position: absolute;
        content: "";
    }
}





