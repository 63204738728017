/*====================
    Counter Style
==========================*/
.counter__style{
    &--1 {
        .title{
            color: #666;
            line-height: 2.5;
        }
        .count{
            position: relative;
            display: inline-block;
            padding: 0 20px;
            &::before{
                position: absolute;
                content: "~";
                left: 0;
            }
        }
    }
}