/*=======================
    Icon Boxes
=========================*/

.icon-box{
    background-color: #fff;
    padding: 35px;
    box-shadow: 0px 0px 22px 0px #dfdfdf;
    .icon-inner{
        max-width: 270px;
        position: relative;
        height: 80px;
        width: 80px;
        margin: 0 auto 24px;
    }
    .icon{
        background-color: $theme-color;
        &::before ,
        &::after{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            border-radius: 50%;
            background: inherit;
        }
        &::before{
            width: 80px;
            height: 80px;
            opacity: .3;
        }

        &::after {
            width: 60px;
            height: 60px;
            opacity: .7;
        } 
        i{
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%,-50%);
            -ms-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
            height: 40px;
            width: 40px;
            line-height: 40px;
            font-size: 21px;
            font-weight: 600;
            color: #fff;
            background: inherit;
            border-radius: 50%;
            text-align: center;
            z-index: 3;
        }
    }
    .content{
        text-align: center;
        .title{
            font-size: 24px;
            font-weight: 700;
        }
        p{
            color: #535353;
            font-weight: 400;
            line-height: 2em;
        }
        .portlo-button{
            a{
                border: 0 none;
                padding: 11px 15px;
                span{
                    font-size: 13px;
                }
            }
        }
    }
}




















