/*==========================
    Mega Menu Css 
===========================*/

.megamenu-wrapper{
    li{
        &.lavel-1{
            display: inline-block;
            padding-left: 0;
            padding-right: 9px;
            position: relative;
            a {
                position: relative;
                margin-left: 13px;
                padding-right: 21px;
                display: inline-block;
                &::before {
                    content: "\f107";
                    font-size: 14px;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    font-family: FontAwesome;
                    font-weight: 900;
                }
            }
            .dropdown__menu{
                -webkit-box-shadow: 0 10px 37px rgba(0, 0, 0, 0.07);
                box-shadow: 0 10px 37px rgba(0, 0, 0, 0.07);
                position: absolute;
                list-style: none;
                background-color: #222222;
                -webkit-animation: hide-animation .5s;
                animation: hide-animation .5s;
                left: 0px;
                display: none;
                width: 300px;
                margin: 0;
                padding: 35px 0 30px;
                min-width: 300px;
                li{
                    padding: 0 40px;
                    a{
                        color: #222222;
                        span{
                            position: relative;
                            padding: 2px 0;
                            overflow: hidden;
                            z-index: 1;
                            font-weight: 500;
                            line-height: 1.58;
                            color: #999;
                            font-size: 14px;
                            -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
                            -o-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
                            transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
                            display: inline-block;
                            margin: 2px 0;
                        }
                    }

                    &.drop-lavel--2{
                        position: relative;
                        .lavel--2{
                            position: absolute;
                            list-style: none;
                            display: none;
                            padding: 30px 0 50px;
                            margin: 0;
                            background: #222222;
                            width: 300px;
                            -webkit-animation: slide-out-to-right .3s;
                            animation: slide-out-to-right .3s;
                            left: 100%;
                            top: 0;
                        }
                        &:hover .lavel--2{
                            display: block;
                            -webkit-animation: slide-in-to-left .3s;
                            animation: slide-in-to-left .3s;
                        }
                    }
                }
            }
            &:hover .dropdown__menu{
                display: block;
                -webkit-animation: show-animation .5s;
                animation: show-animation .5s;
                top: 100%;
            }
        }
    }
}





/* --------------------------------------
    Custom Hide Animation
---------------------------------------*/

@-webkit-keyframes hide-animation {
    0% {
        -webkit-transform: translateY(0);
        opacity: 1
    }
    to {
        -webkit-transform: translateY(60px);
        opacity: 0
    }
}

@keyframes hide-animation {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }
    to {
        -webkit-transform: translateY(60px);
        transform: translateY(60px);
        opacity: 0
    }
}

/* --------------------------------------
    Custom Slide Show Animation
---------------------------------------*/

@-webkit-keyframes show-animation {
    0% {
        -webkit-transform: translateY(60px);
        opacity: 0
    }
    to {
        -webkit-transform: translateY(0);
        opacity: 1
    }
}

@keyframes show-animation {
    0% {
        -webkit-transform: translateY(60px);
        transform: translateY(60px);
        opacity: 0
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }
}


/* --------------------------------------
    Custom Slide Out To Right
---------------------------------------*/

@-webkit-keyframes slide-out-to-right {
    0% {
        -webkit-transform: translateX(0);
        opacity: 1
    }
    to {
        -webkit-transform: translateX(40px);
        opacity: 0
    }
}

@keyframes slide-out-to-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
    to {
        -webkit-transform: translateX(60px);
        transform: translateX(60px);
        opacity: 0
    }
}

/* --------------------------------------
    Custom Slide Out To Left
---------------------------------------*/

@-webkit-keyframes slide-in-to-left {
    0% {
        -webkit-transform: translateX(60px);
        opacity: 0
    }
    to {
        -webkit-transform: translateX(0);
        opacity: 1
    }
}

@keyframes slide-in-to-left {
    0% {
        -webkit-transform: translateX(40px);
        transform: translateX(40px);
        opacity: 0
    }
    to {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1
    }
}








