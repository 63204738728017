/*==================================
 * Banner
==================================*/

.slider-content{
    .title{
        color: $theme-color;
        font-size: 90px;
        font-weight: 900;
        position: relative;
        padding-bottom: 9px;
        margin-bottom: 35px;

        @media #{$lg-layout} {
            font-size: 55px;
        }

        @media #{$md-layout} {
            font-size: 55px;
            padding-bottom: 8px;
            margin-bottom: 11px;
        }

        @media #{$sm-layout} {
            font-size: 55px;
            padding-bottom: 8px;
            margin-bottom: 11px;
        }

        &::before{
            position: absolute;
            width: 50px;
            height: 2px;
            content: "";
            left: 0;
            bottom: 0;
            background: #002257;
        }
    }
    h1{
        color: #161616;
        font-size: 90px;
        line-height: 100px;
        font-weight: 500;
        margin-bottom: 0;

        @media #{$lg-layout} {
            font-size: 46px;
            line-height: 66px;
            br{
                display: none;
            }
        }

        @media #{$laptop-device} {
            font-size: 55px;
            line-height: 71px;
            br{
                display: none;
            }
        }

        @media #{$md-layout} {
            font-size: 46px;
            line-height: 66px;
            br{
                display: none;
            }
        }

        @media #{$sm-layout} {
            font-size: 46px;
            line-height: 66px;
            br{
                display: none;
            }
        }



    }
}

// SLider Style Two
.slide-bg-shape{
    position: relative;
    z-index: 2;
    &::before{
        position: absolute;
        content: "";
        width: 330px;
        height: 100%;
        background: $theme-color;
        left: 50%;
        transform: translateX(-50%);
    }
}

.slide{
    &.fullscreen{
        min-height: 750px;
        width: 100%;
        .slide-parts{
            @media #{$md-layout} {
                padding: 150px 15px;
            }
            @media #{$sm-layout} {
                padding: 150px 15px;
            }
        }
        @media #{$md-layout} {
            min-height: auto;
        }
        @media #{$sm-layout} {
            min-height: auto;
        }
    }
}

.slide-parts {
    height: 100%;
    h1{
        font-size: 75px;
        font-weight: 500;
        @media #{$lg-layout} {
            font-size: 47px;
        }
        @media #{$md-layout} {
            font-size: 47px;
        }
        @media #{$sm-layout} {
            font-size: 47px;
        }
    }
    p{
        font-size: 30px;

        @media #{$lg-layout} {
            font-size: 18px;
        }

        @media #{$md-layout} {
            font-size: 18px;
        }

        @media #{$sm-layout} {
            font-size: 14px;
        }

    }
}
.slider2-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.slider-style--2{
    .slide-thumbnail{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .row{
        height: 100%;
        @media #{$md-layout} {
            height: auto;
        }

    }
}


















