/*========================
Portfolio Grid Area 
=========================*/


.with__gutter--free {
    margin: 0;
    .portfolio{
        padding: 0px;
        margin-bottom: 0px;
    }
}

.with__gutter--30{
    margin: 0 -15px;
    .portfolio{
        padding: 0 15px;
        margin-bottom: 30px;
    }
}

.with__gutter--20{
    margin: 0 -10px;
    .portfolio{
        padding: 0 10px;
        margin-bottom: 20px;
    }
}


.portfolio-grid-metro3 {
    .portfolio-33-33 {
        width: 33.33%;
    }
    .grid-width-2 {
        width: 66.66%;
    }
    .resizer {
        width: 33.33%;
    }
    @media #{$lg-layout} {
        .portfolio-33-33 {
            width: 33.33%;
        }
        .grid-width-2{
            width: 66.66%;
        }
        .resizer {
            width: 33.33%;
        }
    }

    @media #{$md-layout} {
        .portfolio-33-33 {
            width: 50%;
        }
        .grid-width-2{
            width: 100%;
        }
        .resizer {
            width: 50%;
        }
    }
    @media #{$sm-layout} {
        .portfolio-33-33 {
            width: 100%;
        }
        .resizer {
            width: 100%;
        }
    }
}


// portfolio grid metro2
.portfolio-grid-metro2 {
    .portfolio-50 {
        width: 50%;
    }
    .grid-width-2 {
        width: 100%;
    }
    .resizer {
        width: 50%;
    }
    @media #{$sm-layout} {
        .portfolio-50 {
            width: 100%;
        }
        .grid-width-2 {
            width: 100%;
        }
        .resizer {
            width: 100%;
        }
    }

}


// portfolio grid metro4
.portfolio-grid-metro4 {
    .portfolio-25 {
        width: 25%;
    }
    .grid-width-2 {
        width: 50%;
    }
    .resizer {
        width: 25%;
    }

    @media #{$lg-layout} {
        .portfolio-25 {
            width: 50%;
        }
        .grid-width-2 {
            width: 100%;
        }
        .resizer {
            width: 50%;
        }
    }

    @media #{$md-layout} {
        .portfolio-25 {
            width: 50%;
        }
        .grid-width-2 {
            width: 100%;
        }
        .resizer {
            width: 50%;
        }
    }

    @media #{$sm-layout} {
        .portfolio-25 {
            width: 100%;
        }
        .grid-width-2 {
            width: 100%;
        }
        .resizer {
            width: 100%;
        }
    }

    @media #{$large-mobile} {
        .portfolio-25 {
            width: 100%;
        }
        .grid-width-2 {
            width: 100%;
        }
        .resizer {
            width: 100%;
        }
    }

}

// portfolio grid metro4
.portfolio-grid-metro5 {
    .portfolio-20 {
        width: 20%;
    }
    .grid-width-2 {
        width: 40%;
    }
    .resizer {
        width: 20%;
    }

    @media #{$lg-layout} {
        .portfolio-20 {
            width: 25%;
        }
        .grid-width-2 {
            width: 50%;
        }
        .resizer {
            width: 25%;
        }
    }

    @media #{$md-layout} {
        .portfolio-20 {
            width: 50%;
        }
        .grid-width-2 {
            width: 100%;
        }
        .resizer {
            width: 50%;
        }
    }

    @media #{$sm-layout} {
        .portfolio-20 {
            width: 100%;
        }
        .grid-width-2 {
            width: 100%;
        }
        .resizer {
            width: 100%;
        }
    }



}




















