/*=====================
    Team Area 
====================*/
.col-xl-20{
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;

    @media #{$md-layout} {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media #{$sm-layout} {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.team{

    .content{
        padding-top: 20px;
        text-align: center;
        padding-bottom: 20px;

        h5{
            margin-bottom: 5px;
        }
        p{
            color: #252323;
            font-size: 14px;
        }
        .team-social{
            a{  
                color: #252323;
                font-size: 22px;
                display: inline-block;
                margin: 0 10px;
                transition: 0.5s;
                &:hover{
                    color: $theme-color;
                }
                i{

                }
            }
        }
    }

    &.team-style--2{
        .thumbnail {
            position: relative;
            z-index: 2;
            display: inline-block;
            border-radius: 100%;
            img{
                border-radius: 100%;
            }
            &::before{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                -webkit-transition: all .4s;
                -o-transition: all .4s;
                transition: all .4s;
                z-index: 0;
                background-color: $theme-color;
                cursor: pointer;
                border-radius: 100%;
            }

            .team-hover-action {
                position: absolute;
                top: 50%;
                width: 100%;
                text-align : center;
                opacity: 0;
                -webkit-transition: all .4s ease-in-out .1s;
                -o-transition: all .4s ease-in-out .1s;
                transition: all .4s ease-in-out .1s;
                -ms-transform: translateY(20px);
                transform: translateY(20px);
                -webkit-transform: translateY(20px);
                z-index: 2;

                a {
                    color: #ffffff;
                    font-size: 22px;
                    margin: 0 10px;
                    display: inline-block;
                }
            }
        }
        &:hover{
            .thumbnail{
                &::before{
                    opacity: 0.9;
                }
                .team-hover-action{
                    opacity: 1;
                    -ms-transform: translateY(0);
                    transform: translateY(0);
                    -webkit-transform: translateY(0);
                }
            }
        }
    }
}























