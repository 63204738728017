/*=======================
 * Section Title
=====================*/


.section_title--2{
    .title{
        position: relative;
        display: inline-block;
        &::before{
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background: #505050;
        }
    }
}



.section-title--3{
    line-height: 70px;
    font-weight: 500;
    font-size: 60px;
    margin-bottom: 0;
    margin-top: -15px;

    @media #{$laptop-device} {
        line-height: 62px;
        font-size: 46px;
    }

    @media #{$lg-layout} {
        line-height: 58px;
        font-size: 45px;
    }

    @media #{$md-layout} {
        line-height: 50px;
        font-size: 40px;
    }

    @media #{$sm-layout} {
        line-height: 45px;
        font-size: 35px;
    }

}







