/*=============================
    Search Overlay 
===============================*/
.search-flyoveray{
    position: fixed;
    background: #080808;
    z-index: 9;
    visibility: hidden;
    opacity: 0;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    transition: all .25s ease-out;
}

.open-cart-aside{
    .search-flyoveray{
        visibility: visible;
        opacity: .7;
    }
}

.cart-flyout{
    position: fixed;
    top: 0;
    width: 420px;
    padding: 50px;
    min-height: 100vh;
    bottom: 0;
    z-index: 111;
    right: 0;
    visibility: hidden;
    opacity: 0;
    background: #fff;
    transition: all .25s ease-in;
    transform: translate(200px,0);
    -webkit-transform: translate(200px,0);
    box-shadow: 0px 0px 87px 0px rgba(0,0,0,.09);
}

.open-cart-aside{
    .cart-flyout{
        visibility: visible;
        opacity: 1;
        transform: translate(0,0);
        -webkit-transform: translate(0,0);
    }
}



















