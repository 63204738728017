/* Portfolio Details */

.portfolio-details{
    h4 {
        margin: 0;
        padding: 30px 0;
    }
    .project-inner {
        .feature-list {
            padding: 0;
            list-style: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
                flex-basis: 33.33%;
                margin-bottom: 20px;
                font-size: 18px;
                color: #1f2226;
                @media #{$large-mobile} {
                    flex-basis: 50%;
                }
                i {
                    color: #7d7d7d;
                    font-size: 20px;
                    padding-right: 10px;
                }   
            }
        }
    }
}


























