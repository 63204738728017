/*========================
    Header Default 
==========================*/
.draven-haeder {
    @extend %posrelative;
    z-index: 8;
    &.header--transparent{
        @extend %posabsolute;
        top: 0;
        left: 0;
        right: 0;
        width: auto;
    }

    .header-wrap{
        @include flexbox;
        @include flex-wrap(wrap);
        @include align-items(center);
        width: 100%;

        @media #{$lg-layout} {
            justify-content: space-between;
        }
        

        @media #{$md-layout} {
            justify-content: space-between;
        }

        @media #{$sm-layout} {
            justify-content: space-between;
        }

        .header-col{
            @include align-items(center);
            .logo{
                img{
                    display: inline-block;
                }
            }
            &__left{
                @include flex(0 0 25%);
                @media #{$sm-layout} {
                    @include flex(0 0 50%);
                }
            }

            &__center{
                @include flex(0 0 50%);
            }

            &__right{
                @include flexbox;
                @include flex(0 0 25%);
                @include justify-content(flex-end);
            }
        }
    }
}

.header-icon{
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    position: relative;
    a{
        display: inline-block;
        line-height: 30px;
        transition: all .2s ease;
        vertical-align: middle;
        &.popup-round{
            background-color: #fff;
            color: #111;
            height: 80px;
            line-height: 80px;
            width: 80px;
            text-align: center;
            border-radius: 100%;
            @extend %transition;
            &:hover{
                background-color: $theme-color;
                color: #fff;
            }
            i{
                font-size: 28px;
            }
        }

        i{
            display: inline-block;
            font-size: 20px;
            vertical-align: middle;
        }
    }


    .hamburger-trigger{
        &.open{
            i{
                &::before{
                    content: '\f00d';
                }
            }
        }
    }

}



.black-logo-version.headroom--sticky.headroom--not-top {
    background: rgba(255, 255, 255, 0.9);
    background-color: rgba(255, 255, 255, 0.9);
}




.headroom--sticky.headroom--not-top {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    -webkit-animation: headerSlideDown .95s ease forwards;
    animation: headerSlideDown .95s ease forwards;
    padding: 0;
}


@media #{$lg-layout}{
    .headroom--sticky.headroom--not-top{
        padding: 26px 0;
    }
}

@media #{$md-layout}{
    .headroom--sticky.headroom--not-top{
        padding: 26px 0;
    }
}

@media #{$sm-layout}{
    .headroom--sticky.headroom--not-top{
        padding: 26px 0;
    }
}

.headroom--sticky.headroom--not-top {
    background: rgba(0, 0, 0, 0.9);
    background-color: rgba(0, 0, 0, 0.9);
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}



/* ------------------------
    Custom Animation 01 
----------------------------*/

@-webkit-keyframes headerSlideDown {
    0% {
        margin-top: -100px
    }

    to {
        margin-top: 0
    }
}

@keyframes headerSlideDown {
    0% {
        margin-top: -100px
    }

    to {
        margin-top: 0
    }
}






