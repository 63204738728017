/*===========================       
    Humburger Area 
=============================*/
.hamburger-area{
    text-align: left;
    transition: all .52s ease;
    position: fixed;
    height: 100% !important;
    top: 0;
    margin: 0;
    z-index: 99;
    box-shadow: 0 0 15px 0 rgba(0,0,0,.09);
    width: 470px;
    padding: 70px;
    color: #fff;
    background-color: #2f2f2f;
    right: 0;
    transform: translateX(470px);
    -webkit-transform: translateX(470px);

    @media #{$smlg-device} {
        left: -280px;
        width: 280px;
        transform: inherit;
        overflow-x: hidden;
        overflow-y: auto;
        opacity: 0;
        padding: 0;
        padding-top: 70px;
    }

    &.is-visible{
        transform: translateX(0);
        -webkit-transform: translateX(0);

        @media #{$smlg-device} {
            left: 0;
            transform: inherit;
            opacity: 1;
            visibility: visible;
        }
    }
}


/* Hambeger Area */
.hamburger-area{
    .btn-close-search{
        position: absolute;
        left: 30px;
        font-size: 30px;
        line-height: 30px;
        height: 30px;
        top: 50px;
        transition: all .25s ease-out;

        @media #{$smlg-device} {
            top: 35px;
            right: 24px;
            left: auto;
            font-size: 20px;
        }

        button{
            padding: 0;
            border: 0 none;
            color: #fff;
            &:hover{
                transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
                color: #f53e6a;
            }
        }
    }

    .hamburger-menu-main{
        position: relative;
        height: 100%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        -webkit-flex-direction: column;
        .humberger-top{
            flex-grow: 2;
            -webkit-flex-grow: 2;
            .hum-mainmenu{
                ul{
                    @extend %liststyle;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    li{
                        a{
                            font-weight: 400;
                            text-transform: capitalize;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            font-size: 18px;
                            padding: 10px 0;
                            position: relative;
                            display: block;
                            color: #fff;
                            transition: 0.5s;
                            &:hover{
                                color: $theme-color;
                            }
                        }
                    }
                }
            }
        }
        .humberger-bottom{
            margin: 50px 0 0;
            p{
                color: #fff;
                font-size: 14px;
                margin: 20px 0 30px;
            }
            .hm-address{
                @extend %liststyle;
                li{
                    color: #fff;
                    a{
                        color: #fff;
                    }
                    &:not(:last-child){
                        padding-bottom: calc(15px/2);
                    }
                    &:not(:first-child){
                        margin-top: calc(15px/2);
                    }
                }
            }
            .social-icons{
                margin: 0 -8px;
                margin-top: 70px;
                margin-bottom: 40px;
                a{
                    margin: 0 8px;
                    width: 40px;
                    display: inline-block;
                    height: 40px;
                    border: 1px solid #575656;
                    line-height: 40px;
                    border-radius: 100%;
                    text-align: center;
                    transition: 0.5s;
                    color: #ffffff;
                    &:hover{
                        background: $theme-color;
                        border: 1px solid $theme-color;
                        color: #ffffff;
                    }
                }
            }
            .hm-copyright{
                margin: 30px 0 0;
                p{
                    font-size: 12px;
                    line-height: 1.4074;
                    color: #fff;
                }
            }
        }
    }

}




/* ================================
    Responsive Menu 
==================================*/


.menu-primary-menu-1{
    @extend %liststyle;
    li{
        a{
            color: silver;
        }
        &.has-dropdown{
            > a{
                position: relative;
                &::after{
                    position: absolute;
                    content: "\f107";
                    font-family: FontAwesome;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 12px;
                    width: 50px;
                }
                &.is-visiable{
                    color: $theme-color;
                    &::after{
                        content: "\f106";
                    }
                }
            }
        }
    }

    > li{
        > a{
            display: block;
            padding: 14px 20px;
            font-size: 16px;
        }
        .sub-menu{
            @extend %liststyle;
            display: none;
            background-color: rgba(255,255,255,.05);
            padding: 5px 0;
            margin: 5px 0;

            > li{
                width: 100%;
                > a{
                    padding: 10px 30px;
                    font-size: 1em;
                    display: block;
                }

                &.has-label--3 {
                    .lavel--3{
                        @extend %liststyle;
                        display: none;
                        background-color: rgba(255,255,255,.05);
                        padding: 5px 0;
                        margin: 5px 0;
                        li{
                            width: 100%;
                            a{
                                padding: 10px 30px;
                                font-size: 1em;
                                padding-right: 40px;
                                padding-left: 40px;
                                display: block;
                            }
                        }
                    }

                }
                
            }
        }
    }
}







































