/*==========================
    Portfolio Button 
============================*/
.messonry-button {
    margin: 0 -18px;

    button {
        border: 0 none;
        padding: 0;
        margin: 0;
        position: relative;
        padding-bottom: 10px;
        font-weight: 600;
        font-size: 14px;
        color: #161616;
        margin: 0 18px;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: $theme-color;
            -webkit-transform: scale3d(0, 5, 1);
            transform: scale3d(0, 5, 1);
            -webkit-transform-origin: 0% 50%;
            transform-origin: 0% 50%;
            -webkit-transition: -webkit-transform 0.3s;
            transition: transform 0.3s;
            -webkit-transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
            transition-timing-function: cubic-bezier(1, 0.68, 0.16, 0.9);
        }

        span {
            &.filter-text {}
        }

        &.is-checked {
            &::before {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }
        }

        &:hover {

            &::before {
                -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
            }
        }

    }

    &.messonry-button--2 {
        button {
            padding: 0;
            margin: 0;
            border: 0 none;
            outline: none;
            margin: 0 5px;
            margin-bottom: 15px !important;

            &::before {
                display: none;
            }

            span {
                &.filter-text {
                    position: relative;
                    padding: 0 25px;
                    display: block;
                    height: 50px;
                    line-height: 50px;
                    font-weight: 600;
                    font-size: 14px;
                    -webkit-transition: -webkit-transform 0.2s;

                    &::before,
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border: 2px solid $theme-color;
                        -webkit-transition: -webkit-transform 0.2s;
                    }

                    &::before {
                        transition: transform 0.2s;
                        border-width: 0 2px;
                        -webkit-transform: scale3d(1, 0, 1);
                        transform: scale3d(1, 0, 1);
                        -webkit-transform-origin: 0% 50%;
                        transform-origin: 0% 50%;
                    }

                    &::after {
                        transition: transform 0.2s;
                        border-width: 2px 0;
                        -webkit-transform: scale3d(0, 1, 1);
                        transform: scale3d(0, 1, 1);
                        -webkit-transform-origin: 0% 0%;
                        transform-origin: 0% 0%;
                        -webkit-transition-delay: 0.2s;
                        transition-delay: 0.2s;
                    }
                }
            }

            &.is-checked {
                span {
                    color: $theme-color;
                    &::before {
                        -webkit-transform: scale3d(1, 1, 1);
                        transform: scale3d(1, 1, 1);
                    }
                    &::after {
                        -webkit-transform: scale3d(1, 1, 1);
                        transform: scale3d(1, 1, 1);
                    }
                }
            }
            &:hover {
                span {
                    color: $theme-color;
                    &::before {
                        -webkit-transform: scale3d(1, 1, 1);
                        transform: scale3d(1, 1, 1);
                    }
                    &::after {
                        -webkit-transform: scale3d(1, 1, 1);
                        transform: scale3d(1, 1, 1);
                    }
                }
            }

        }
    }
}