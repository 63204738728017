/*********************************************************************************

	Template Name: Portlo Minimal Portfolio Bootstrap4 Template
	Note: This is style css.

**********************************************************************************/

/**************************************************************
	
	STYLESHEET INDEXING
	|
	|
	|___ Default Styles
	|	|
	|	|___ variables
	|	|___ mixins
	|	|___ reset
	|	|___ typography
	|	|___ extend
	|	|___ animations
	|	|___ shortcode
	|	|___ spacing
	|
	|
	|___Header Styles
	|	|___ header
	|	|___ navigation
	|	|___ humberger
	|	|___ cart-flyover
	|	|___ search
	|	|___ megamenu
	|
	|
	|___Elements Styles
	|	|___ button 
	|	|___ section-title 
	|	|___ video
	|	|___ contact
	|	|___ blog
	|	|___ counters
	|	|___ team
	|	|___ icon-box
	|
	|
	|___Portfolio Styles
	|	|___ portfolio 
	|	|___ slick-dots 
	|	|___ portfolio-grid 
	|	|___ portfolio-button 
	|	|___ portfolio-details 
	|
	|
	|
	|___Template Styles
	|	|___ banner 
	|	|___ about 
	|	|___ service 
	|	|___ testimonial 
	|	|___ skill 
	|	|___ blog-details 
	|	|___ sidebar 
	|
	|___Footer Styles
	|	|___ footer 
	|
	|
	|___ END STYLESHEET INDEXING

***************************************************************/


/**************************************
    Default Styles
***************************************/
@import 'default/variables';
@import 'default/mixins';
@import 'default/reset';
@import 'default/typography';
@import 'default/extend';
@import 'default/animations';
@import 'default/shortcode';
@import 'default/spacing';


/**************************************
	Header Styles
***************************************/
@import 'header/header';
@import 'header/navigation';
@import 'header/humberger';
@import 'header/cart-flyover';
@import 'header/search';
@import 'header/megamenu';


/**************************************
	Elements Styles
***************************************/
@import 'elements/button';
@import 'elements/section-title';
@import 'elements/video';
@import 'elements/contact';
@import 'elements/blog';
@import 'elements/counters';
@import 'elements/team';
@import 'elements/icon-box';




/**************************************
	Portfolio Styles
***************************************/
@import 'portfolio/portfolio';
@import 'portfolio/slick-dots';
@import 'portfolio/portfolio-grid';
@import 'portfolio/portfolio-button';
@import 'portfolio/portfolio-details';




/**************************************
	Template Styles
***************************************/
@import 'template/banner';
@import 'template/about';
@import 'template/service';
@import 'template/testimonial';
@import 'template/skill';
@import 'template/blog-details';
@import 'template/sidebar';





/**************************************
	Footer Styles
***************************************/
@import 'footer/footer';













