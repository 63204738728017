/*===========================
    About Area
=============================*/

.about-content{
    p{
        color: #ffffff;
        font-size: 20px;
        line-height: 35px;
    }
    .section-title--3{
        margin-bottom: 30px;
    }
}


















