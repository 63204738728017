.blog_style {
    &--1 {
        background-color: #fff;
        border: 1px solid #e3e3e3;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, .07);

        &.border-none {
            border: 0 none;
        }

        &.bg-transparent {
            background: transparent;
            box-shadow: none;

            &:hover {
                box-shadow: none;
            }
        }

        &.left-nopadding {
            .inner {
                padding-left: 0;
            }
        }

        &.overlay-image {
            .blog-overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #222;
                background-size: cover;
                background-position: 50%;
                background-repeat: no-repeat;
                opacity: 0;
                visibility: hidden;
                -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            }

            &:hover {
                .blog-overlay {
                    opacity: 1;
                    visibility: visible;
                }

                .inner {
                    .title {
                        a{
                            @extend %textwhite;
                        }
                    }
                    .category{
                        a{
                            @extend %textwhite;
                        }
                    }

                    .meta {
                        li {
                            @extend %textwhite;

                            a {
                                @extend %textwhite;
                            }
                        }
                    }

                    p {
                        @extend %textwhite;
                    }

                    .blog-btn {
                        .button-link {
                            &.button__transparent--border {
                                border: 1px solid #ffffff;
                                @extend %textwhite;
                            }
                        }
                    }
                }
            }
        }

        .inner {
            padding: 40px;
            position: relative;
            z-index: 1;
            
            @media #{$laptop-device} {
                padding: 40px 21px;
            }
            @media #{$lg-layout} {
                padding: 40px 20px;
            }
            @media #{$md-layout} {
                padding: 35px 20px;
            }
            @media #{$sm-layout} {
                padding: 30px 15px;
            }

            .title{
                a{
                    color: $heading-color;
                    transition: 0.5s;
                    &:hover{
                        color: $theme-color;
                    }
                }
            }
            .meta {
                @extend %liststyle;
                margin: 0 -10px;

                li {
                    @extend %inlineBlock;
                    font-size: 12px;
                    line-height: 1;
                    margin: 0 10px;

                    a {
                        @extend %transition;
                        color: #535353;

                        &:hover {
                            @extend %themecolor;
                        }
                    }
                }
            }

            .blog-btn {
                .button-link {
                    @extend %textUpper;
                    border: 1px solid #d1d1d1;
                    padding: 12px 40px;
                    color: $heading-color;
                     transition: 0.5s;
                     &:hover{
                         background: $theme-color;
                         color: $white;
                         border: 1px solid $theme-color;
                     }
                }
            }
        }

        &:hover {
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, .07);
        }
    }
}