/*=====================
 * Button
========================*/

.portlo-button{
    a{
        border-style: solid;
        border-width: 1px;
        border-color: #2f2f2f;
        color: #2f2f2f;
        background-color: rgba(255, 255, 255, 0);
        line-height: 20px;
        padding: 20px 55px;
        font-size: 16px;
        display: inline-block;
        span{
            line-height: 1;
            font-weight: 700;
            font-size: 14px;
        }
        &:hover{
            color: #fff;
            background-color: $theme-color;
            border-color: $theme-color;
        }
    }
    &.btn-white{
        a{
            border-color: #ffffff;
            span{
                color: #fff;
            }
            &:hover{
                color: #fff;
                background-color: $theme-color;
                border-color: $theme-color;
            }
        }
        
    }
}




 

/*------------------------------
    Scroll Up 
--------------------------------*/
#scrollUp {
    width: 40px;
    height: 40px;
    background-color: #191919;
    border: 3px solid #eeeeee;
    color: #191919;
    right: 20px;
    bottom: 60px;
    text-align: center;
    overflow: hidden;
    border-radius: 50px;
    z-index: 9811 !important;
}

@media only screen and (max-width: 767px) {
    #scrollUp {
        display: none !important;
    }
}

#scrollUp i {
    display: block;
    line-height: 31px;
    font-size: 22px;
    color: #fff;
}

#scrollUp:hover i {
    animation-name: fadeInUp;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}




/* ========================
    Slider Video Play 
=========================*/


.yu2fvl {
    z-index: 9999;
    top: 0;
}

.yu2fvl-iframe {
    display: block;
    height: 100%;
    width: 100%;
    border: 0;
}

.yu2fvl-overlay {
    z-index: 9998;
    background: #000;
    opacity: 0.8;
}

.yu2fvl-close {
    position: absolute;
    top: 1px;
    left: 100%;
    display: inline-block;
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    background: #f10 !important;
    background-color: #f10 !important;
    color: #ffffff;
    border: 0;
    background: none;
    cursor: pointer;
}



@media #{$md-layout} {
    .yu2fvl-close {
        left: auto;
        right: 0;
        top: auto;
        bottom: 100%;
    }
}

@media #{$sm-layout} {
    .yu2fvl-close {
        left: auto;
        right: 0;
        top: auto;
        bottom: 100%;
    }

    .video {
        h2 {
            color: #ffffff;
            font-size: 32px;
        }

        h4 {
            font-size: 22px;
            line-height: 32px;
        }
    }
}

 

